<template>
    <div id="index">
        <div class="content_top">
            <div class="content_top_left content_top_con">
                <div class="content_top_left_gonggao" @click="goNoticeList()">
                    <img src="../assets/images/announcement.png" @click="goNoticeList()">
                    <span @click="goNoticeList()">公告</span>
                </div>
                <div class="content_top_left_content">
                    <div style="width: 98%;">
                        <el-carousel height="0.6rem" direction="vertical" :autoplay="true">
                            <el-carousel-item v-for="(item, index) in noticeList" :key="index">
                                <div class="flex flex-child-center flex-between" @click="noticeDetails(item)">
                                    <div style="cursor: pointer">{{ item.title }}</div>
                                    <div>详情
                                        <img src="../assets/images/right.png">
                                    </div>
                                </div>

                            </el-carousel-item>
                        </el-carousel>
                    </div>

                </div>
            </div>
            <div class="content_top_right content_top_con">
                <img src="../assets/images/policy.png" @click="goPoliciesList()">
                <span class="span1" @click="goPoliciesList()">优惠政策</span>
                <div class="span2">
                    <el-carousel height="0.6rem" direction="vertical" :autoplay="true">
                        <el-carousel-item v-for="(item, index) in policiesList" :key="index">
                            <div class="flex flex-child-center flex-between" @click="policiesDetails(item)">
                                <div style="cursor: pointer">{{ item.title }}</div>
                                <el-button>查看详情<img src="../assets/images/whiteRight.png"></el-button>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>

            </div>
        </div>
        <div class="content_body">
            <el-container style="height: 100%;">
                <el-aside width="1.94rem" style="background: #FFFFFF;">
                    <div class="side-bar">
                        <el-col :span="12">
                            <el-menu default-active="2" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" @select="handleSelect" background-color="#ffffff" text-color="#333333" active-text-color="#FFFFFF" v-if="barList.length > 0">
                                <template v-for="(item, index) in barList">
                                    <!--叶子级菜单 -->
                                    <template v-if="!item.children">
                                        <el-menu-item :index="item.id.toString()">
                                            {{ item.name }}
                                        </el-menu-item>
                                    </template>
                                    <!--一级点击-->
                                    <template v-else>
                                        <el-submenu :index="item.id.toString()">
                                            <template slot="title">
                                                <span>{{ item.name }}</span>
                                            </template>
                                            <el-menu-item-group>
                                                <template v-for="(item1, index1) in item.children">
                                                    <!--叶子级菜单-->
                                                    <template v-if="!item1.children">
                                                        <el-menu-item :index="item1.id.toString()">
                                                            {{ item1.name }}
                                                        </el-menu-item>
                                                    </template>
                                                    <!--二级点击-->
                                                    <template v-else>
                                                        <el-submenu :index="item1.id.toString()">
                                                            <template slot="title">
                                                                <span>{{ item1.name }}</span>
                                                            </template>
                                                            <el-menu-item-group>
                                                                <template v-for="(item2, index2) in item1.children">
                                                                    <!--叶子级菜单-->
                                                                    <template v-if="!item2.children">
                                                                        <el-menu-item :index="item2.id.toString()">
                                                                            {{ item2.name }}
                                                                        </el-menu-item>
                                                                    </template>
                                                                    <!--三级点击-->
                                                                    <template v-else>
                                                                        <el-submenu :index="item2.id.toString()">
                                                                            <template slot="title">
                                                                                <span>{{ item2.name }}</span>
                                                                            </template>
                                                                            <el-menu-item-group>
                                                                                <template v-for="(item3, index3) in item2.children">
                                                                                    <!--叶子级菜单-->
                                                                                    <template v-if="!item3.children">
                                                                                        <el-menu-item :index="item3.id.toString()">
                                                                                            {{ item3.name }}
                                                                                        </el-menu-item>
                                                                                    </template>
                                                                                    <!--四级点击-->
                                                                                    <template v-else>
                                                                                        <template slot="title">
                                                                                            <span>{{ item3.name }}</span>
                                                                                        </template>
                                                                                        <el-menu-item-group>
                                                                                            <template v-for="(item4, index4) in item3.children">
                                                                                                <!--叶子级菜单-->
                                                                                                <template v-if="!item4.children">
                                                                                                    <el-menu-item :index="item4.id.toString()">
                                                                                                        {{ item4.name }}
                                                                                                    </el-menu-item>
                                                                                                </template>
                                                                                                <!--五级点击-->
                                                                                                <template v-else>
                                                                                                    <template slot="title">
                                                                                                        <span>{{ item4.name }}</span>
                                                                                                    </template>
                                                                                                    <el-menu-item-group>
                                                                                                        <template v-for="(item5, index5) in item4.children">
                                                                                                            <!--叶子级菜单-->
                                                                                                            <template v-if="!item5.children">
                                                                                                                <el-menu-item :index="item5.id.toString()">
                                                                                                                    {{ item5.name }}
                                                                                                                </el-menu-item>
                                                                                                            </template>
                                                                                                            <!--五级点击-->
                                                                                                            <template v-else>
                                                                                                                <template slot="title">
                                                                                                                    <span>{{ item5.name }}</span>
                                                                                                                </template>
                                                                                                            </template>
                                                                                                        </template>
                                                                                                    </el-menu-item-group>
                                                                                                </template>
                                                                                            </template>
                                                                                        </el-menu-item-group>
                                                                                    </template>
                                                                                </template>
                                                                            </el-menu-item-group>
                                                                        </el-submenu>
                                                                    </template>
                                                                </template>
                                                            </el-menu-item-group>
                                                        </el-submenu>
                                                    </template>
                                                </template>
                                            </el-menu-item-group>

                                        </el-submenu>
                                    </template>
                                </template>

                            </el-menu>
                        </el-col>
                        <!--                        <div v-for="(item,index) in barList" class="side-bar-item" :key="index" :class="{side_bar_item_btna:count == index}"  @click="sidebarClick(index,item)">-->
                        <!--                            <span>{{item.name}}</span>-->
                        <!--                            <img src="../assets/images/rightjt.png">-->
                        <!--                        </div>-->
                    </div>
                </el-aside>

                <el-container v-if="$store.state.show_type == 0">
                    <el-main v-infinite-scroll="loadMore" :infinite-scroll-disabled="busy" immediate-check="false" infinite-scroll-distance="1">
                        <div style="height: calc(100vh-200px);overflow:auto!important;">
                            <div class="content-main" v-masonry transition-duration="0s" v-if="mainList.length > 0">
                                <div v-for="(item, index) in mainList" :key="index" class="content-main-box" v-masonry-tile @click="details(item)">
                                    <el-popover placement="right" width="400" trigger="hover">
                                        <div class="content-main-box-image" slot="reference" @click.stop style="width: 60px;height: 60px;text-align: center">
                                            <el-image v-if="item.goods_image" :src="item.goods_image" style="cursor: pointer;height: 50px;" fit="contain" :preview-src-list="[item.goods_image]">
                                            </el-image>
                                            <img v-else src="../assets/images/moreImage.png">
                                            <img v-else src="">
                                            <img class="remai" v-if="item.is_recommend == 1" src="../assets/images/tuijian.png">
                                            <img class="remai" v-if="item.promotion == 1" src="../assets/images/remai.png">
                                            <div class="content-main-box-text" v-if="login_status" style="margin-top: 8px;">
                                                <span style="color: #4B83F0;font-size: 0.16rem;font-weight: 500;margin-left: 0.05rem;" v-if="$store.state.turnOnOff">￥{{ item.price_profit }}</span>
                                                <span style="color: #4B83F0;font-size: 0.16rem;font-weight: 500;margin-left: 0.05rem;" v-else>￥{{ item.old_price }}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="flex flex-wrap flex-child-center" v-if="item.goods_images.length > 0">
                                                <div v-for="(item1) in item.goods_images" class="popover_img">
                                                    <!-- <img :src="item1"> -->
                                                    <el-image :src="item1" :preview-src-list="item.goods_images">
                                                    </el-image>
                                                </div>
                                            </div>
                                            <div class="flex flex-wrap flex-child-center" v-else>
                                                <div class="popover_img">
                                                    <!-- <img :src="scope.row.goods_image"> -->
                                                    <el-image :src="item.goods_image" :preview-src-list="[item.goods_image]">
                                                    </el-image>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <el-button slot="reference">click 激活</el-button>-->
                                    </el-popover>
                                    <div style="width: 100%">
                                        <div class="content-main-box-text">
                                            {{ item.goods_name }}
                                        </div>
                                        <div class="flex flex-between flex-child-end margin-10 no-margin-right no-margin-bottom no-margin-left">
                                            <div v-if="item.isShow == 1" style="width: 82%;margin-right: 1%;color: #000000;">
                                                <span class="mainHeight">{{ item.note }}</span>
                                            </div>
                                            <div v-else style="width: 82%;margin-right: 1%;color: #000000;">
                                                <span class="maxnone">{{ item.note }}</span>
                                            </div>
                                            <!--                                        <span class="centent-all" @click="click(index,2)">{{ item.spec }}<img src="../assets/images/zhankai.png"></span>-->
                                            <span v-if="item.isShow == 1" class="centent-all" @click.stop="click(index, 2)">全部<img src="../assets/images/zhankai.png"></span>
                                            <span v-if="item.isShow == 2" class="centent-all" @click.stop="click(index, 1)">收起<img src="../assets/images/shouqi.png"></span>
                                        </div>
                                        <div class="flex flex-child-center flex-between margin-12 no-margin-bottom no-margin-left no-margin-right">
                                            <div class="content-main-box-price" @click.stop>
                                                <!--                                                <span v-if="$store.state.turnOnOff">￥{{item.price_profit}}</span>-->
                                                <!--                                                <span v-else>￥{{item.old_price}}</span>-->
                                                <div class="fuzhi" @click.stop="fuzhi(item)">复制</div>
                                                <el-popover placement="right" width="400" trigger="hover" @show="showPopver(item, index, 'myChart_heart')" v-if="login_status">
                                                    <img src="../assets/images/card.png" slot="reference" style="cursor: pointer;">
                                                    <div style="text-align: center;">
                                                        <div style="font-size: 0.14rem;color: #666666;">价格走势</div>
                                                        <div style="font-size: 0.12rem;color: #333333;font-weight: 500;margin-top: 0.14rem;">{{ item.goods_name }}</div>
                                                        <div class="charts-wrap">
                                                            <div :id="`myChart_heart${index}`" :style="{ width: '4.5rem', height: '3rem', marginTop: '0.1rem' }"></div>
                                                        </div>
                                                    </div>
                                                </el-popover>
                                                <div class="fuzhi" style="width: 1rem;margin-left: 0.1rem" v-else>会员可查看价格</div>
                                            </div>
                                            <div class="addCard" @click.stop="btnAdd(item)" v-if="login_status">加入购物车</div>
                                            <!--                                            <div  class="num_input flex">-->
                                            <!--                                                <el-button class="btn_minute" @click="btnMinute(item)">-</el-button>-->
                                            <!--                                                <div class="input">{{item.cart_number}}</div>-->
                                            <!--                                                &lt;!&ndash;                                            <el-input v-model="" disabled></el-input>&ndash;&gt;-->
                                            <!--                                                <el-button class="btn_add" @click="btnAdd(item)">+</el-button>-->
                                            <!--                                                &lt;!&ndash;                                            <el-input-number v-model="item.cart_number" @change="handleChange($event,item)" :min="0" :max="10000"></el-input-number>&ndash;&gt;-->
                                            <!--                                            </div>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 文字形式-->
                            <div v-if="mainList.length == 0" style="margin-top: 10%">
                                <el-empty :image="require('../assets/images/null.png')" description="暂无商品"></el-empty>
                            </div>
                        </div>

                    </el-main>
                </el-container>

                <el-container v-if="$store.state.show_type == 1">
                    <el-main v-infinite-scroll="loadMore" :infinite-scroll-disabled="busy" immediate-check="false" infinite-scroll-distance="1" style="overflow-anchor: none;">
                        <div style="height: calc(100vh-200px);">
                            <!-- 文字形式-->
                            <div class="content-main" v-masonry transition-duration="0s" v-if="mainList.length > 0">
                                <div style="display: flex;justify-content: space-between;width: 100%">
                                    <el-table :data="leftList" stripe style="width: 49%" border :row-style="{ height: '55px' }" @row-click="godetails">
                                        <el-table-column prop="goods_code" label="编号" width="70" />
                                        <el-table-column prop="goods_name" label="复制" width="50">
                                            <template slot-scope="scope">
                                                <div class="fuzhi" style="font-size: 0.12rem;padding: 0.01rem 0.02rem;background-color: #eee;text-align: center;border-radius: 0.01rem;" @click.stop="fuzhi(scope.row)">复制</div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="goods_name" label="主图" width="90">
                                            <template slot-scope="scope">
                                                <!-- <el-popover placement="right" width="400" trigger="hover">
                                                    <div class="content-main-box-image" slot="reference" @click.stop style="width: 50px;height: 50px;text-align: center">
                                                        <el-image v-if="scope.row.goods_image" :src="scope.row.goods_image" style="cursor: pointer;height: 50px;" fit="contain" :preview-src-list="scope.row.goods_images.length > 0 ? scope.row.goods_images : [scope.row.goods_image]">
                                                        </el-image>
                                                        <img v-else src="../assets/images/moreImage.png">
                                                        <img v-else src="">
                                                        <img class="remai" v-if="scope.row.is_recommend == 1" src="../assets/images/tuijian.png">
                                                        <img class="remai" v-if="scope.row.promotion == 1" src="../assets/images/remai.png">
                                                    </div>
                                                    <div>
                                                        <div class="flex flex-wrap flex-child-center" v-if="scope.row.goods_images.length > 0">
                                                            <div v-for="(item1) in scope.row.goods_images" class="popover_img">
                                                                <el-image :src="item1" :preview-src-list="scope.row.goods_images">
                                                                </el-image>
                                                            </div>
                                                        </div>
                                                        <div class="flex flex-wrap flex-child-center" v-else>
                                                            <div class="popover_img">
                                                                <el-image :src="scope.row.goods_image" :preview-src-list="[scope.row.goods_image]">
                                                                </el-image>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </el-popover> -->
                                                <el-popover placement="right" width="400" trigger="hover" @show="showPopver(scope.row, scope.$index, 'myChart_heart_two')">
                                                    <img src="../assets/images/card.png" slot="reference" style="cursor: pointer;">
                                                    <div style="text-align: center;">
                                                        <div style="font-size: 0.14rem;color: #666666;">价格走势</div>
                                                        <div style="font-size: 0.12rem;color: #333333;font-weight: 500;margin-top: 0.14rem;">{{ scope.row.goods_name }}</div>
                                                        <div class="charts-wrap">
                                                            <div :id="`myChart_heart_two${scope.$index}`" :style="{ width: '4.5rem', height: '3rem', marginTop: '0.1rem' }"></div>
                                                        </div>
                                                    </div>
                                                </el-popover>
                                                <span v-if="login_status">
                                                    <span style="color: #4B83F0;font-size: 0.16rem;font-weight: 500;margin-left: 0.05rem;" v-if="$store.state.turnOnOff">￥{{ scope.row.price_profit }}</span>
                                                    <span style="color: #4B83F0;font-size: 0.16rem;font-weight: 500;margin-left: 0.05rem;" v-else>￥{{ scope.row.old_price }}</span>
                                                </span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="goods_name" label="商品名称" show-overflow-tooltip>
                                            <template slot-scope="scope">
                                                <div>{{ scope.row.goods_name }}</div>
                                                <div style="padding-left: 10px;font-size: 12px;overflow: hidden;">{{ scope.row.note }}</div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="goods_name" label="价格" width="85" align="center">
                                            <template slot-scope="scope">
                                                <div class="addCardtable" @click.stop="btnAdd(scope.row)" v-if="login_status">加入购物车</div>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                    <el-table :data="rightList" stripe style="width: 49%;margin-left: 0.1rem" border :row-style="{ height: '55px' }" @row-click="godetails">
                                        <el-table-column prop="goods_code" label="编号" width="70" />
                                        <el-table-column prop="goods_name" label="复制" width="50">
                                            <template slot-scope="scope">
                                                <div class="fuzhi" style="font-size: 0.12rem;padding: 0.01rem 0.02rem;background-color: #eee;text-align: center;border-radius: 0.01rem;" @click.stop="fuzhi(scope.row)">复制</div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="goods_name" label="主图" width="90">
                                            <template slot-scope="scope">
                                                <!-- <el-popover placement="right" width="400" trigger="hover">
                                                    <div class="content-main-box-image" slot="reference" @click.stop style="width: 50px;height: 50px;text-align: center">
                                                        <el-image v-if="scope.row.goods_image" :src="scope.row.goods_image" style="cursor: pointer;height: 50px;" fit="contain" :preview-src-list="scope.row.goods_images.length > 0 ? scope.row.goods_images : [scope.row.goods_image]">
                                                        </el-image>
                                                        <img v-else src="../assets/images/moreImage.png">
                                                        <img v-else src="">
                                                        <img class="remai" v-if="scope.row.is_recommend == 1" src="../assets/images/tuijian.png">
                                                        <img class="remai" v-if="scope.row.promotion == 1" src="../assets/images/remai.png">
                                                    </div>
                                                    <div>
                                                        <div class="flex flex-wrap flex-child-center" v-if="scope.row.goods_images.length > 0">
                                                            <div v-for="(item1) in scope.row.goods_images" class="popover_img">
                                                                <el-image :src="item1" :preview-src-list="scope.row.goods_images">
                                                                </el-image>
                                                            </div>
                                                        </div>
                                                        <div class="flex flex-wrap flex-child-center" v-else>
                                                            <div class="popover_img">
                                                                <el-image :src="scope.row.goods_image" :preview-src-list="[scope.row.goods_image]">
                                                                </el-image>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </el-popover> -->
                                                <el-popover placement="right" width="400" trigger="hover" @show="showPopver(scope.row, scope.$index, 'myChart_heart_three')">
                                                    <img src="../assets/images/card.png" slot="reference" style="cursor: pointer;">
                                                    <div style="text-align: center;">
                                                        <div style="font-size: 0.14rem;color: #666666;">价格走势</div>
                                                        <div style="font-size: 0.12rem;color: #333333;font-weight: 500;margin-top: 0.14rem;">{{ scope.row.goods_name }}</div>
                                                        <div class="charts-wrap">
                                                            <div :id="`myChart_heart_three${scope.$index}`" :style="{ width: '4.5rem', height: '3rem', marginTop: '0.1rem' }"></div>
                                                        </div>
                                                    </div>
                                                </el-popover>
                                                <span v-if="login_status">
                                                    <span style="color: #4B83F0;font-size: 0.16rem;font-weight: 500;margin-left: 0.05rem;" v-if="$store.state.turnOnOff">￥{{ scope.row.price_profit }}</span>
                                                    <span style="color: #4B83F0;font-size: 0.16rem;font-weight: 500;margin-left: 0.05rem;" v-else>￥{{ scope.row.old_price }}</span>
                                                </span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="goods_name" label="商品名称" :show-overflow-tooltip="true">
                                            <template slot-scope="scope">
                                                <div>{{ scope.row.goods_name }}</div>
                                                <div style="padding-left: 10px;font-size: 12px;overflow: hidden;">{{ scope.row.note }}</div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="goods_name" label="价格" width="85" align="center">
                                            <template slot-scope="scope">
                                                <div class="addCardtable" @click.stop="btnAdd(scope.row)" v-if="login_status">加入购物车</div>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </div>

                    </el-main>
                </el-container>
            </el-container>
        </div>
        <div class="bottom_text">{{ $store.state.text }}</div>
        <shop-details custom-class="dialog" :visible.sync="dialog.save" ref="shopDetails" @closed="dialog.save = false"></shop-details>
    </div>
</template>

<script>
import { mapState } from "vuex";
import Masonry from "masonry-layout";
import shopDetails from "./shopDetails.vue";
export default {
    name: "Index",
    components: {
        shopDetails,
    },
    data() {
        return {
            dialog: {
                save: false
            },
            barList: [],
            count: 0,
            form: {
                keyword: '',
                goods_type_id: '',
            },
            loading: false,
            mainList: [],
            leftList: [],
            rightList: [],
            noticeList: [],
            policiesList: [],
            num: 0,
            busy: false,
            page: 0,
            total_count: 0,
            visibles: [],
            login_status: this.$store.state.user.token && this.$store.state.user.is_vip == 1 ? true : false,
            // login_status:true,
        };
    },
    computed: {
        // ...mapState(['keyword'])
    },
    watch: {
    },
    async mounted() {
        // this.InterfaceList()
        this.goodsTypeList()
        this.getData()
        this.$store.commit('settab', 0)
    },
    created() {
        this.$store.watch((state, getters) => {
            return state.search
        }, (res) => {
            this.page = 1
            this.mainList = []
            this.form.keyword = this.$store.state.keyword
            this.form.goods_type_id = ''
            if (this.$store.state.router_path) {
                this.form = this.$store.state.router_path
                this.page = this.$store.state.router_path.page
                this.$store.commit('setRouterPath', '')
            }
            this.InterfaceList()
        })
    },
    updated() {
        var elem = document.querySelector('.content-main');//图片父容器
        var msnry = new Masonry(elem, {
            // options
            itemSelector: '.content-main-box',//图片子容器
            // columnWidth: 200,         //列宽
        });
    },
    methods: {
        /**
         * 复制
         * */
        fuzhi(item) {
            let a = ''
            if (this.$store.state.turnOnOff) {
                a = item.goods_name + ' ' + '￥' + item.price_profit + ' ' + item.goods_spec
            } else {
                a = item.goods_name + ' ' + '￥' + item.old_price + ' ' + item.goods_spec
            }
            //创建input标签
            var input = document.createElement('input')
            //将input的值设置为需要复制的内容
            input.value = a;
            //添加input标签
            document.body.appendChild(input)
            //选中input标签
            input.select()
            //执行复制
            document.execCommand('copy')
            //成功提示信息
            this.$message.success('复制成功!')
            //移除input标签
            document.body.removeChild(input)
        },
        /**
         * 商品详情
         * */
        details(item) {
            // this.$router.push({
            //     name: 'shopDetails',
            //     query: item
            // })

            this.dialog.save = true
            this.$nextTick(() => {
                this.$refs.shopDetails.open('add').setData(item)
            })
        },
        godetails(row, event, column) {
            this.dialog.save = true
            this.$nextTick(() => {
                this.$refs.shopDetails.open('add').setData(row)
            })
            // this.$router.push({
            //     name: 'shopDetails',
            //     query: row
            // })
        },
        handleOpen(key, keyPath) {
        },
        handleClose(key, keyPath) {
        },
        /**
         * 侧边栏点击
         * */
        // sidebarClick(index,item) {
        //     this.page = 1
        //     this.mainList = []
        //     this.count = index
        //     this.form.goods_type_id = this.barList[this.count].id
        //     this.InterfaceList()
        // },
        /**
         *  侧边导航栏点击
         * */
        handleSelect(key, keyPath) {
            this.page = 1
            this.mainList = []
            this.form.goods_type_id = key
            this.InterfaceList(false)
        },
        loadMore() {
            this.busy = true
            setTimeout(() => {
                this.page++;
                this.InterfaceList(true)
            }, 500)
        },
        /**
         *  echarts图
         * */
        showPopver(item, index, idname) {
            this.$nextTick(() => {
                // 基于准备好的dom，初始化echarts实例
                let myChart_heart = this.$echarts.init(document.getElementById(idname + index))
                let xdata = []
                let data = []
                for (let key in item.price_list) {
                    if (item.price_list.hasOwnProperty(key)) {
                        xdata.push(key)
                        data.push(item.price_list[key])
                    }
                }
                // 绘制图表
                myChart_heart.setOption({
                    grid: {
                        left: '50'
                    },
                    xAxis: {
                        type: 'category',
                        data: xdata,
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: false
                        },
                        z: 10,
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    yAxis: {
                        type: 'value',
                    },
                    series: [
                        {
                            data: data,
                            type: 'line',
                            smooth: true,
                            itemStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        {
                                            offset: 0,
                                            color: '#B4EC51'
                                        },
                                        {
                                            offset: 1,
                                            color: '#429321'
                                        }
                                    ])
                                }
                            },
                        }
                    ]
                })
            })
        },
        async getData() {
            await this.$axios.post('api/kuerp/order/index/notice', this.$qs.stringify({})).then(res => {
                if (res.data.code == 200) {
                    this.noticeList = res.data.data.list
                }
            })
            await this.$axios.post('api/kuerp/order/index/policies', this.$qs.stringify({})).then(res => {
                if (res.data.code == 200) {
                    this.policiesList = res.data.data.list
                }
            })
        },
        click(index, eve) {
            this.$nextTick(() => {
                this.mainList[index].isShow = eve
                if (this.mainList[index].spec == '收起') {
                    this.mainList[index].spec = '全部'
                } else {
                    this.mainList[index].spec = '收起'
                }
            })
            this.$forceUpdate();
        },
        /**
         *  添加购物车
         * */
        btnAdd(item) {
            this.$axios.post('api/kuerp/order/index/addCart', this.$qs.stringify({
                goods_id: item.id,
                unit_id: item.unit_id,
                number: 1
            })).then(res => {
                if (res.data.code == 200) {
                    this.mainList.map((item1, index1) => {
                        if (item.id == item1.id) {
                            item1.cart_number = res.data.data
                        }
                    })
                    this.$message.success('加入购物车成功!')
                    //购物车数量接口
                    this.$store.dispatch('GetGwc')
                    // this.barList = res.data.data
                    // this.page = 0
                    // this.mainList = []
                    // this.InterfaceList()
                    //购物车数量接口
                    this.$store.dispatch('GetGwc')
                }
            })
        },
        /**
         *  减少购物车
         * */
        btnMinute(item) {
            if (item.cart_number <= 0) {
                this.$message({ message: '该商品不能减少了！' });
                return;
            }
            this.$axios.post('api/kuerp/order/index/editCart', this.$qs.stringify({
                goods_id: item.id,
                unit_id: item.unit_id,
                number: item.cart_number - 1
            })).then(res => {
                if (res.data.code == 200) {
                    // this.page = 0
                    // this.barList = res.data.data
                    // this.InterfaceList()
                    this.mainList.map((item1, index1) => {
                        if (item.id == item1.id) {
                            item1.cart_number = res.data.data
                        }
                    })
                    //购物车数量接口
                    this.$store.dispatch('GetGwc')
                }
            })
        },
        /**
         * 公告详情点击
         * */
        noticeDetails(item) {
            this.$router.push({
                name: 'noticeDetails',
                query: item
            })
        },
        /**
         * 优惠政策详情点击
         * */
        policiesDetails(item) {
            this.$router.push({
                name: 'policiesDetails',
                query: item
            })
        },

        goPoliciesList() {
            this.$router.push({
                name: 'policiesList',
            })
        },
        goNoticeList() {
            this.$router.push({
                name: 'noticeList',
            })
        },
        async goodsTypeList() {
            await this.$axios.post('api/kuerp/order/index/goodsTypeList', this.$qs.stringify({})).then(res => {
                if (res.data.code == 200) {
                    this.barList = res.data.data
                    // this.InterfaceList()
                }
            })
        },
        addCard(item) {
            if (this.$store.state.user.token && this.$store.state.user.is_vip != 1) {
                this.$message({ message: '会员才可以下单，请联系管理员' });
                return;
            }
            this.$axios.post('api/kuerp/order/index/addCart', this.$qs.stringify({
                goods_id: item.id,
                unit_id: item.unit_id,
                number: 1,
                goods_attr: item.attr_data_id
            })).then(res => {
                if (res.data.code == 200) {
                    this.$message({ message: '加入购物车成功' });
                    //购物车数量接口
                    this.$store.dispatch('GetGwc')
                }
            })
        },
        InterfaceList(flag) {
            // this.$vLoading.show();
            // if(this.form.goods_type_id == '') {
            //     this.form.goods_type_id = this.barList[this.count].id
            // }
            this.form.keyword = this.$store.state.keyword
            this.form.page = this.page
            this.form.limit = 24
            this.$axios.post('api/kuerp/order/index/goodsGoodsList', this.$qs.stringify(
                this.form
            )).then(res => {
                if (res.data.code == 200) {
                    if (flag) {
                        res.data.data.list.map((item, index) => {
                            item.goods_images.unshift(item.goods_image)
                            item.isShow = 1
                            if (this.leftList.length > this.rightList.length) {
                                this.rightList.push(item)
                            } else {
                                this.leftList.push(item)
                            }
                        })
                        this.mainList = this.mainList.concat(res.data.data.list)
                        // this.mainList.map((item,index) =>{
                        //     item.isShow = 1
                        // })
                        this.busy = false
                    } else {
                        this.rightList = [];
                        this.leftList = [];
                        res.data.data.list.map((item, index) => {
                            item.goods_images.unshift(item.goods_image)
                            item.isShow = 1
                            if (this.leftList.length > this.rightList.length) {
                                this.rightList.push(item)
                            } else {
                                this.leftList.push(item)
                            }
                        })
                        this.mainList = res.data.data.list
                        this.busy = false
                    }

                    this.$store.commit('setSeach', 1)
                    // this.$vLoading.hide();
                }

            })
        },
    },
}
</script>
<style>
.el-tooltip__popper {
    max-width: 300px;
}
</style>
<style scoped lang="scss">
::v-deep {

    .el-col-12 {
        width: 100%;
    }

    .el-aside {
        background: #F1F1F1 !important;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .el-menu-item.is-active {
        background: #4B83F0 !important;
    }

    .el-menu-item {
        margin-bottom: 0.05rem !important;
        border-radius: 0.04rem !important;
    }

    .el-submenu__title {
        border-radius: 0.04rem !important;
    }

    .el-menu {
        background: #F1F1F1 !important;
        border: none !important;
    }

    .el-submenu {
        margin-bottom: 0.05rem !important;
        border-radius: 0.04rem !important;
    }

    .el-submenu .el-menu-item {
        margin-bottom: 0 !important;
        border-radius: 0 !important;
    }

    .el-menu-item-group {
        .el-menu-item-group__title {
            padding: 0 !important;
        }
    }

    .el-menu-item-group__title {
        padding: 0 !important;
    }
}

//.el-submenu__title {
//
//}
.num_input {
    .btn_add {
        background: #4B83F0;
        color: #FFFFFF;
    }

    .input {
        width: 0.3rem !important;
        height: 0.18rem !important;
        line-height: 0.18rem !important;
        text-align: center;
    }

    ::v-deep {
        .el-button {
            width: 0.18rem !important;
            height: 0.18rem !important;
            padding: 0 !important;
        }
    }
}

.charts-wrap {
    width: 4.5rem;
    height: 3rem;
    display: flex;
}

.popover_img {
    width: 0.76rem;
    height: 0.76rem;
    margin-right: 0.15rem;
    margin-bottom: 0.15rem;

    img {
        width: 100%;
        height: 100%;
    }
}

::v-deep {
    .el-main {
        padding: 0;
        margin-left: 0.2rem;
        overflow: auto !important;
    }
}

.content-main {
    display: flex;
    //justify-content: space-between;
    flex-wrap: wrap;
    //overflow: auto!important;
    /* 设置滚动条的样式 */

    //height:100%;
    ::v-deep {

        .el-input-number {
            width: 0.7rem;
            height: 0.2rem;
        }

        .el-input-number__increase,
        .el-input-number__decrease {
            width: 0.2rem;
            height: 0.2rem;
            border: none;
            border-radius: 0.04rem;
        }

        .el-input__inner {
            height: 0.2rem;
            line-height: 0.2rem;
        }

        .el-input {
            height: 0.2rem;
        }

        .el-input-number {
            line-height: 0.2rem;
        }

        .el-input-number .el-input__inner {
            padding-left: 0.2rem;
            padding-right: 0.2rem;
            border: none;
        }
    }

    .content-main-box:nth-child(3n) {
        //border-right:0!important;
        //margin-right: 0!important;
        //padding-right: 0!important;
    }

    .addCard {
        width: 0.8rem;
        height: 0.3rem;
        background: #4b83f0;
        border-radius: 0.07rem;
        text-align: center;
        line-height: 0.32rem;
        font-size: 0.12rem !important;
        font-weight: 400;
        color: #fff;
    }

    .addCardtable {
        width: 0.8rem;
        height: 0.3rem;
        background: #4b83f0;
        border-radius: 0.07rem;
        text-align: center;
        line-height: 0.32rem;
        font-size: 0.12rem !important;
        font-weight: 400;
        color: #fff;
        margin-top: 0.02rem;
    }

    .content-main-box {
        width: 30%;
        display: flex;
        //padding-bottom: 0.2rem;
        //padding-right: 0.2rem;
        margin-right: 0.2rem !important;
        padding: 0.2rem;
        cursor: pointer;
        border: 0.01rem dotted #D8D8D8;
        background: #FFFFFF;
        margin-bottom: 0.1rem !important;

        .content-main-box-image {
            position: relative;

            img {
                width: 0.76rem;
                height: 0.76rem;
                margin-right: 0.1rem;
            }

            .remai {
                position: absolute;
                left: 0;
                top: 0;
                width: 0.35rem;
                height: 0.35rem;
            }
        }

        .content-main-box-text {
            width: 100%;
            font-size: 0.16rem;
            font-weight: 500;
            color: #393E4C;
            display: flex;
            align-items: center;

            spanHuo {
                height: 0.16rem;
                background: #E6EFFF;
                border-radius: 0.02rem;
                border: 0.01rem solid #4B83F0;
                font-size: 0.12rem;
                font-weight: 400;
                color: #4B83F0;
                line-height: 0.16rem;
                padding: 0 0.03rem;
                margin-right: 0.05rem;
            }
        }

        .content-main-box-price {
            font-size: 0.24rem;
            font-weight: bold;
            color: #333333;
            display: flex;
            align-items: center;

            img {
                width: 0.18rem;
                height: 0.18rem;
                margin-left: 0.1rem;
            }

            .fuzhi {
                width: 0.5rem;
                height: 0.2rem;
                background: #F3F3F3;
                border-radius: 0.02rem;
                text-align: center;
                line-height: 0.2rem;
                font-size: 0.12rem !important;
                font-weight: 400;
                color: #999999;
                //display: block;
                //width: 1rem;
            }
        }

        .centent-all {
            //padding: 0.02rem 0.1rem;
            //background: #F3F3F3;
            border-radius: 0.12rem;
            font-size: 0.12rem;
            font-weight: 400;
            color: #000000;
            cursor: pointer;

            img {
                width: 0.09rem;
                height: 0.05rem;
                margin-left: 0.03rem;
            }
        }

        .maxnone {
            width: 100%;
            height: auto;
            display: block;
        }

        .mainHeight {
            width: 100%;
            height: 0.2rem;
            overflow: hidden;
            display: block;
        }
    }

}

.content_body {
    background: #F1F1F1;
    height: 9.1rem;
    padding: 0.2rem 0 0.2rem 0.2rem;
    overflow: auto !important;

    .side_bar_item_btna {
        background: #DBE6FC !important;
        color: #4B83F0 !important;
        padding: 0 0.1rem 0 0.15rem !important;
        border-left: 0.05rem solid #4B83F0;
    }

    .side-bar {
        width: 100%;
    }

    .side-bar-item {
        width: 1.3rem;
        height: 0.48rem;
        background: #F5F5F5;
        font-size: 0.14rem;
        font-weight: 400;
        color: #2F2F2F;
        margin-bottom: 0.05rem;
        padding: 0 0.1rem 0 0.2rem;
        display: flex;
        align-items: center;

        span {
            width: 0.75rem;
        }

        img {
            width: 0.05rem;
            height: 0.08rem;
            margin-left: 0.05rem;
        }
    }
}

.bottom_text {
    font-size: 0.14rem;
    font-weight: 500;
    color: #999999;
    text-align: center;
    margin-top: 0.1rem;
}

#index {
    width: 19.1rem;
    height: 10.2rem;
    margin: 0 auto;
    margin-top: 0.1rem;
}

.flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content_top {
    .content_top_left {
        @extend .flex-between;
        background: #F1F1F1;
        font-size: 0.16rem;

        .content_top_left_gonggao {
            width: 10.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #4B83F0;
            border-radius: 0.04rem 0px 0px 0.04rem;
            color: #FFFFFF;

            img {
                width: 0.18rem;
                height: 0.18rem;
                margin-right: 0.08rem;
            }
        }

        .content_top_left_content {
            padding: 0 0.2rem;
            width: 22rem;
            @extend .flex-between;
            color: #666666;

            img {
                width: 0.075rem;
                height: 0.12rem;
                margin-left: 0.05rem;
            }
        }

    }

    .content_top_right {
        background: linear-gradient(180deg, #E1EAF8 0%, #EFF3F9 100%);
        display: flex;
        align-items: center;

        .span1 {
            width: 1.5rem;
            font-size: 0.24rem;
            font-weight: 500;
            color: #333333;
        }

        .span2 {
            width: 6rem;
            font-size: 0.14rem;
            font-weight: 400;
            color: #999999;
            margin: 0 0.22rem;

            ::v-deep .el-carousel__button {
                width: 0 !important;
                height: 0 !important;
            }

            ::v-deep .el-button {
                width: 1.2rem;
                height: 0.3rem;
                line-height: 0.3rem;
                border-radius: 0.23rem;
                background-color: #4B83F0 !important;
                border-color: #4B83F0 !important;
                color: #FFFFFF;
                font-size: 0.12rem;
                display: flex;
                align-items: center;

                img {
                    width: 0.075rem;
                    height: 0.12rem;
                    margin-left: 0.05rem;
                }

            }
        }

        img {
            width: 0.52rem;
            height: 0.52rem;
            margin-left: 0.32rem;
            margin-right: 0.2rem;
        }

    }

    .content_top_con {
        width: 49%;
        height: 0.6rem;
        border-radius: 0.04rem;
        line-height: 0.6rem;
    }
}

::v-deep .el-table .el-table__cell {
    padding: 0.05rem 0 !important;
}

::v-deep .el-table .el-table__body tr>td,
::v-deep .el-table .el-table__body tr>th,
::v-deep .el-table .el-table__fixed-right-patch,
::v-deep .el-table .el-table__fixed-left-patch {
    border-color: #CBCBCB !important;
}

::v-deep .el-table {
    border-color: #CBCBCB !important;
}

::v-deep .el-table th.el-table__cell.is-leaf,
.el-table td.el-table__cell {
    border-color: #CBCBCB !important;
}

::v-deep .el-table--group,
.el-table--border {
    border: 1px solid #CBCBCB;
}

::v-deep .el-table th.el-table__cell.is-leaf,
.el-table td.el-table__cell {
    background-color: #eeeeee;
}

::v-deep .el-carousel__indicator--vertical .el-carousel__button {
    width: 0;
    height: 0;
}
</style>
